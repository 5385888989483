/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useState, useNavigation} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import SyncItemForm from '@shared/modules/Inventory/forms/SyncItemForm';
import SyncItemFormSummariesList from 'modules/Item/components/SyncItemFormSummariesList';
import SyncItemFormsHeader from 'modules/Item/components/SyncItemFormsHeader';

const Container = Styled.Touchable`
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 16px;
  padding-vertical: 10px;
`;

const Name = Styled.H7`
  flex: 1;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const Content = Styled.View`
  padding-horizontal: 6px;
`;

const Touchable = Styled.Touchable`
`;

const DashedLine = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.border};
  border-style: dashed;
  flex: 1;
  margin-horizontal: 10px;
`;

const EmptySubtitle = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
  text-align: center;
  padding-bottom: 12px;
`;

const getSyncItemForms = ({syncRoomForm}) => {
  const takeSyncItemForms = [];
  const takeCartonSyncItemForms = [];
  const leaveSyncItemForms = [];
  const leaveCartonSyncItemForms = [];

  syncRoomForm.syncCollectionForm.syncItemForms.forEach((syncItemForm) => {
    const isCarton = syncItemForm.kind === 'CARTON';
    if (SyncItemForm.getTakeCount(syncItemForm) > 0) {
      if (isCarton) {
        takeCartonSyncItemForms.push(syncItemForm);
      } else {
        takeSyncItemForms.push(syncItemForm);
      }
    }
    if (SyncItemForm.getLeaveCount(syncItemForm) > 0) {
      if (isCarton) {
        leaveCartonSyncItemForms.push(syncItemForm);
      } else {
        leaveSyncItemForms.push(syncItemForm);
      }
    }
  });

  return {
    takeSyncItemForms,
    takeCartonSyncItemForms,
    leaveSyncItemForms,
    leaveCartonSyncItemForms,
  };
};

const SyncRoomFormSummaryItem = ({syncRoomForm}) => {
  const {navigator, params} = useNavigation();
  const {takeSyncItemForms, takeCartonSyncItemForms, leaveSyncItemForms, leaveCartonSyncItemForms} =
    getSyncItemForms({syncRoomForm});
  const hasTakeItems = takeSyncItemForms.length > 0;
  const hasTakeCartons = takeCartonSyncItemForms.length > 0;
  const hasLeaveItems = leaveSyncItemForms.length > 0;
  const hasLeaveCartons = leaveCartonSyncItemForms.length > 0;
  const hasTakeItemsOrCartons = hasTakeItems || hasTakeCartons;
  const hasLeaveItemsOrCartons = hasLeaveItems || hasLeaveCartons;
  const hasItemsOrCartons = hasTakeItemsOrCartons || hasLeaveItemsOrCartons;
  const [isExpanded, setIsExpanded] = useState(hasItemsOrCartons);

  // When we initially delete a room, we just toggle isDeleted to true on the form.
  // The room will get deleted eventually once the user saves the updates, but on the
  // ui we want to remove the deleted room right away.
  if (syncRoomForm.isDeleted) {
    return null;
  }

  return (
    <Container
      onLongPress={() => {
        navigator.navigate('ShowRoom', {jobUuid: params.jobUuid, roomUuid: syncRoomForm.uuid});
      }}
    >
      <Header>
        <Name>{syncRoomForm.name}</Name>
        <Space width={10} />
        <SyncItemFormsHeader syncItemForms={syncRoomForm.syncCollectionForm.syncItemForms} />
        <Space width={20} />
        <Touchable onPress={() => setIsExpanded(!isExpanded)}>
          <Icon
            color={colors.gray.primary}
            size={14}
            source={isExpanded ? Icon.ChevronDown : Icon.ChevronUp}
          />
        </Touchable>
      </Header>
      {isExpanded && (
        <React.Fragment>
          <Line />
          <Content>
            <Space height={12} />
            <SyncItemFormSummariesList
              listKey={`TAKE-ITEMS-${syncRoomForm.uuid}`}
              kind={'TAKE'}
              syncItemForms={takeSyncItemForms}
            />
            {hasTakeItems && hasTakeCartons && (
              <React.Fragment>
                <Space height={3} />
                <DashedLine />
                <Space height={8} />
              </React.Fragment>
            )}
            <SyncItemFormSummariesList
              listKey={`TAKE-CARTONS-${syncRoomForm.uuid}`}
              kind={'TAKE'}
              syncItemForms={takeCartonSyncItemForms}
            />
            {hasTakeItemsOrCartons && hasLeaveItemsOrCartons && (
              <React.Fragment>
                <Space height={3} />
                <DashedLine />
                <Space height={8} />
              </React.Fragment>
            )}
            <SyncItemFormSummariesList
              listKey={`LEAVE-${syncRoomForm.uuid}`}
              kind={'LEAVE'}
              syncItemForms={[...leaveSyncItemForms, ...leaveCartonSyncItemForms]}
            />
            {hasItemsOrCartons ? (
              <Space height={7} />
            ) : (
              <EmptySubtitle>No items added</EmptySubtitle>
            )}
          </Content>
        </React.Fragment>
      )}
    </Container>
  );
};

export default SyncRoomFormSummaryItem;
